<template>
  <div>
    <!-- App Header -->
    <div class="appHeader bg-primary text-light">
      <div class="left">
        <a href="javascript:void(0)" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
          <ion-icon name="menu-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">
        <img src="../assets/img/logo.png" alt="logo" class="logo">
      </div>
      <div class="right">
        <!--                <a href="javascript:void(0)" class="headerButton">-->
        <!--                    <ion-icon class="icon" name="notifications-outline"></ion-icon>-->
        <!--                    <span class="badge badge-danger">4</span>-->
        <!--                </a>-->
        <!--                <a href="javascript:void(0)" class="headerButton">-->
        <!--                    <img src="../assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged w32">-->
        <!--                    <span class="badge badge-danger">6</span>-->
        <!--                </a>-->
      </div>
    </div>

    <!-- * App Header -->
    <router-view @msgloaded="unread_msgs=$event"/>

    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
      <router-link :to="{name: 'MetricsWallet'}" class="item" :class="[$route.name==='RelaiEarn Wallet'?'active':'']" >
        <div class="col">
          <ion-icon name="pie-chart-outline"></ion-icon>
          <strong>Wallet</strong>
        </div>
      </router-link>
      <router-link v-if="!getUser.data.is_admin" :to="{name: 'Support'}" class="item" :class="[$route.name==='Support'?'active':'']" >
        <div class="col">
          <ion-icon name="chatbubbles-outline"></ion-icon>
          <strong>Support</strong>
          <span class="badge badge-primary">{{unread_msgs}}</span>
        </div>
      </router-link>
      <router-link v-else :to="{name: 'SupportMessages'}" class="item" :class="[$route.name==='SupportMessages'?'active':'']" >
        <div class="col">
          <ion-icon name="chatbubbles-outline"></ion-icon>
          <strong>Supports</strong>
          <!--                    <span class="badge badge-primary">0</span>-->
        </div>
      </router-link>
      <router-link :to="{name: 'Transactions'}" class="item" :class="[$route.name==='Transactions'?'active':'']" >
        <div class="col">
          <ion-icon name="apps-outline"></ion-icon>
          <strong>Transaction</strong>
        </div>
      </router-link>

      <router-link :to="{name: 'Settings'}" class="item" :class="[$route.name==='Settings'?'active':'']" >
        <div class="col">
          <ion-icon name="settings-outline"></ion-icon>
          <strong>Settings</strong>
        </div>
      </router-link>

      <a href="javascript:void(0)" data-bs-target="#postLogout" data-bs-toggle="modal" class="item">
        <div class="col">
          <ion-icon name="log-out-outline"></ion-icon>
          <strong>Sign out</strong>
        </div>
      </a>

    </div>
    <!-- * App Bottom Menu -->

    <!-- App Sidebar -->
    <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <!-- profile box -->
            <div class="profileBox pt-2 pb-2">
              <div class="image-wrapper">
                <img src="../assets/img/metriclogo_color.png" alt="image" class="imaged  w36">
              </div>
              <div class="in">
                <strong>{{getUser.data.name}}</strong>
                <div class="text-muted">
                  #MTW-{{getUser.id.substr(0, 10)}}...
                </div>
              </div>
              <a href="javascript:void(0)" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <!-- * profile box -->
            <!-- balance -->
            <div class="sidebar-balance">
              <div class="listview-title">Balance</div>
              <div class="in">
                <h1 class="amount">{{getUserFiatSymbol}}
                  <!--                                    <span v-if="getTotalFiatBalance<=0">{{Math.floor(getProfile.data.balance)}}</span>-->
                  <!--                                    <span v-else>{{Math.floor(getTotalFiatBalance)}}</span>-->
                  <span>{{Math.floor(getTotalFiatBalance).toLocaleString()}}</span>
                </h1>
              </div>
            </div>
            <!-- * balance -->

            <!-- action group -->
            <!--                        <div class="action-group">-->
            <!--                            <a href="javascript:void(0)" class="action-button">-->
            <!--                                <div class="in">-->
            <!--                                    <div class="iconbox">-->
            <!--                                        <ion-icon name="add-outline"></ion-icon>-->
            <!--                                    </div>-->
            <!--                                    Deposit-->
            <!--                                </div>-->
            <!--                            </a>-->
            <!--                            <a href="javascript:void(0)" class="action-button">-->
            <!--                                <div class="in">-->
            <!--                                    <div class="iconbox">-->
            <!--                                        <ion-icon name="arrow-down-outline"></ion-icon>-->
            <!--                                    </div>-->
            <!--                                    Withdraw-->
            <!--                                </div>-->
            <!--                            </a>-->
            <!--                            <a href="javascript:void(0)" class="action-button">-->
            <!--                                <div class="in">-->
            <!--                                    <div class="iconbox">-->
            <!--                                        <ion-icon name="arrow-forward-outline"></ion-icon>-->
            <!--                                    </div>-->
            <!--                                    Send-->
            <!--                                </div>-->
            <!--                            </a>-->
            <!--                            <a href="javascript:void(0)" class="action-button">-->
            <!--                                <div class="in">-->
            <!--                                    <div class="iconbox">-->
            <!--                                        <ion-icon name="card-outline"></ion-icon>-->
            <!--                                    </div>-->
            <!--                                    My Cards-->
            <!--                                </div>-->
            <!--                            </a>-->
            <!--                        </div>-->
            <!-- * action group -->

            <!-- navigation -->
            <div class="listview-title mt-1">Services</div>
            <ul class="listview flush transparent no-line image-listview">
              <li>
                <a href="javascript:void(0)" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="pricetags-outline"></ion-icon>
                  </div>
                  <div class="in">
                    Escrow
                    <span class="badge badge-warning">coming soon</span>
                  </div>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="document-lock-outline"></ion-icon>
                  </div>
                  <div class="in">
                    Custody
                    <span class="badge badge-warning">coming soon</span>
                  </div>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="wallet-outline"></ion-icon>
                  </div>
                  <div class="in">
                    WaaS
                    <span class="badge badge-warning">coming soon</span>
                  </div>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="key-outline"></ion-icon>
                  </div>
                  <div class="in">
                    Defi
                    <span class="badge badge-warning">coming soon</span>
                  </div>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="analytics-outline"></ion-icon>
                  </div>
                  <div class="in">
                    Mine Pools
                    <span class="badge badge-warning">coming soon</span>
                  </div>
                </a>
              </li>
            </ul>
            <div v-if="getUser.data.is_admin" class="listview-title mt-1">Admin <small class="text-info">(only visible to you)</small></div>
            <ul v-if="getUser.data.is_admin"  class="listview flush transparent no-line image-listview">
              <li @click.prevent="$globalFunc.dismissModal('#sidebarPanel')">
                <router-link :to="{name: 'Wallet'}" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="pie-chart-outline"></ion-icon>
                  </div>
                  <div class="in">Wallets</div>
                </router-link>
              </li>
              <li @click.prevent="$globalFunc.dismissModal('#sidebarPanel')">
                <router-link :to="{name: 'Deposits'}" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="wallet-outline"></ion-icon>
                  </div>
                  <div class="in">Deposits</div>
                </router-link>
              </li>
              <li @click.prevent="$globalFunc.dismissModal('#sidebarPanel')">
                <router-link :to="{name: 'Withdrawals'}" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="archive-outline"></ion-icon>
                  </div>
                  <div class="in">Withdrawals</div>
                </router-link>
              </li>
              <li @click.prevent="$globalFunc.dismissModal('#sidebarPanel')">
                <router-link :to="{name:'Users'}" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="people-outline"></ion-icon>
                  </div>
                  <div class="in">Users</div>
                </router-link>
              </li>
              <li @click.prevent="$globalFunc.dismissModal('#sidebarPanel')">
                <router-link :to="{name:'CardApplications'}" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="card-outline"></ion-icon>
                  </div>
                  <div class="in">Card Applications</div>
                </router-link>
              </li>
              <li @click.prevent="$globalFunc.dismissModal('#sidebarPanel')">
                <router-link :to="{name:'HWTokenApplications'}" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="hardware-chip-outline"></ion-icon>
                  </div>
                  <div class="in">HW Token Applications</div>
                </router-link>
              </li>
              <li @click.prevent="$globalFunc.dismissModal('#sidebarPanel')">
                <router-link :to="{name:'Contacts'}" class="item">
                  <div class="icon-box bg-primary">
                    <ion-icon name="chatbubbles-outline"></ion-icon>
                  </div>
                  <div class="in">Contacts</div>
                </router-link>
              </li>
            </ul>
            <!-- * menu -->
          </div>
          <div class="modal-footer">
            <div class="container-fluid">
              <div class="row justify-content-start">
                <div id="google_translate_element"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- * App Sidebar -->



    <!--        &lt;!&ndash; iOS Add to Home Action Sheet &ndash;&gt;-->
    <!--        <div class="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabindex="-1" role="dialog">-->
    <!--            <div class="modal-dialog" role="document">-->
    <!--                <div class="modal-content">-->
    <!--                    <div class="modal-header">-->
    <!--                        <h5 class="modal-title">Add to Home Screen</h5>-->
    <!--                        <a href="javascript:void(0)" class="close-button" data-bs-dismiss="modal">-->
    <!--                            <ion-icon name="close"></ion-icon>-->
    <!--                        </a>-->
    <!--                    </div>-->
    <!--                    <div class="modal-body">-->
    <!--                        <div class="action-sheet-content text-center">-->
    <!--                            <div class="mb-1"><img src="../assets/img/icon/192x192.png" alt="image" class="imaged w64 mb-2">-->
    <!--                            </div>-->
    <!--                            <div>-->
    <!--                                Install <strong>RelaiEarn Wallet</strong> on your iPhone's home screen.-->
    <!--                            </div>-->
    <!--                            <div>-->
    <!--                                Tap <ion-icon name="share-outline"></ion-icon> and Add to homescreen.-->
    <!--                            </div>-->
    <!--                            <div class="mt-2">-->
    <!--                                <button class="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>-->
    <!--                            </div>-->
    <!--                        </div>-->

    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        &lt;!&ndash; * iOS Add to Home Action Sheet &ndash;&gt;-->


    <!--        &lt;!&ndash; Android Add to Home Action Sheet &ndash;&gt;-->
    <!--        <div class="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabindex="-1"-->
    <!--             role="dialog">-->
    <!--            <div class="modal-dialog" role="document">-->
    <!--                <div class="modal-content">-->
    <!--                    <div class="modal-header">-->
    <!--                        <h5 class="modal-title">Add to Home Screen</h5>-->
    <!--                        <a href="javascript:void(0)" class="close-button" data-bs-dismiss="modal">-->
    <!--                            <ion-icon name="close"></ion-icon>-->
    <!--                        </a>-->
    <!--                    </div>-->
    <!--                    <div class="modal-body">-->
    <!--                        <div class="action-sheet-content text-center">-->
    <!--                            <div class="mb-1">-->
    <!--                                <img src="../assets/img/icon/192x192.png" alt="image" class="imaged w64 mb-2">-->
    <!--                            </div>-->
    <!--                            <div>-->
    <!--                                Install <strong>RelaiEarn Wallet</strong> on your Android's home screen.-->
    <!--                            </div>-->
    <!--                            <div>-->
    <!--                                Tap <ion-icon name="ellipsis-vertical"></ion-icon> and Add to homescreen.-->
    <!--                            </div>-->
    <!--                            <div class="mt-2">-->
    <!--                                <button class="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        &lt;!&ndash; * Android Add to Home Action Sheet &ndash;&gt;-->

    <!-- internet connection -->
    <div id="online-status" class="bg-warning text-center text-dark">No internet connection</div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import MTBG from "../assets/img/metric-qr-code.png"

export default {
  name: "Container",
  data(){
    return {
      unread_msgs: 0,
      MTBG
    }
  },
  computed: {
    ...mapGetters('user', [
      'getUser',
      'getProfile',
      'getUserFiatSymbol'
    ]),
    ...mapGetters('wallet', ['getTotalFiatBalance'])
  },
  methods: {

  },
  mounted() {
    setTimeout(()=>{
      $('#loader').fadeOut();
      this.$store.dispatch('wallet/autoTradeCrypto')
    }, 1000);
    setTimeout(()=>{
      this.$store.dispatch('transaction/handlePaymentConfirmations')
    }, 2000)
    setInterval(()=>{
      if(navigator.onLine){
        $('#online-status').fadeOut("slow")
      }else{
        $('#online-status').fadeIn("slow")
      }
    }, 2000)
    let script_1 = document.createElement('script');
    script_1.type = "text/javascript"
    script_1.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    let body_1 = document.querySelector('body');
    body_1.append(script_1)
  }
}
</script>

<!--<script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>-->

<style scoped>
#sidebarPanel .modal-body{
  background-repeat: no-repeat;
  background-size: 150px 150px;
  background-position: bottom right;
}
#online-status{
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
}
</style>